import React from "react";
import {CustomButton} from "./CustomButton";

class RedirectButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showButton: false,
        }
    }

    componentWillUnmount = () => {
        clearTimeout(this.timmerHandle);
    };

    componentWillReceiveProps = (nextProps) => {
        //only the items changed, pick up video
        if (JSON.stringify(this.props.playingVideo) !== JSON.stringify(nextProps.playingVideo)) {
            this.setState({
                showButton: false,
            });
            if (nextProps.playingVideo) {
                if (nextProps.playingVideo.button.buttonDetails.countSeconds) {
                    this.timerHandle = setTimeout(() => {
                        this.setState({
                            showButton: true,
                        })
                    }, (nextProps.playingVideo.button.buttonDetails.countSeconds) * 1000);
                }
                else {
                    this.setState({
                        showButton: true,
                    })
                }
            }
        }
    }; // componentWillReceiveProps()

    render() {

        let redirectButton = null;
        if (this.props.playingVideo) {
            if (this.props.playingVideo.button && this.props.playingVideo.button.active) {
                redirectButton = (<div className="text-center" style={{paddingTop: '1em'}}><CustomButton name="redirect"
                                                                                                         editMode={false}
                                                                                                         details={this.props.playingVideo.button}
                                                                                                         live={true}/>
                </div>);
            }
        }
        return (<div>{redirectButton}</div>)
    }

} // RedirectButton

export {RedirectButton};