import React from "react";
import ReactDOM from "react-dom";
import {CustomBlock} from './CustomBlock.jsx';
import {WebinarListItem} from './WebinarListItem.jsx';

const jQuery = window.jQuery, bootbox = window.bootbox, moment = window.moment;


class WebinarList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            maxKey: -1,
            hideItemList: false,
        }
    }

    customBlockMount = (component) => {
        if (this.props.customBlockMount) {
            this.props.customBlockMount(component);
        }
    };

    add = () => {
        let newItems = this.state.items.slice();
        let count = 0;

        for (let item of newItems) {

            if (item.videoCode === '' || item.hour === '' || item.minute === '') {
                count++;
            }
        }

        if (count > 0) {
            bootbox.alert('Please complete the webinar form then add new');
        }
        else {
            newItems.push({
                videoCode: '',
                hour: 12,
                minute: 30,
                timeZone: moment().utcOffset(),
                meridiem: 'pm',
                name: '',
                key: this.state.maxKey + 1,
                youtubeControl: true,
                button: {
                    active: false,
                    buttonDetails: {
                        label: 'Continue',
                        url: '',
                        newTab: true,
                        size: 0,
                        className: 'btn btn-default',
                        style: {
                            borderRadius: 0,
                            backgroundColor: '#999999',
                            color: '#FFFFFF'
                        }
                    }
                }
            });
            this.setState({maxKey: this.state.maxKey + 1});
            this.setState({items: newItems});
            let node = ReactDOM.findDOMNode(this.refs.bottomWeb);
            if (node != null) {
                let height = window.pageYOffset + node.getBoundingClientRect().top;
                jQuery("html, body").animate({scrollTop: height}, 600);
            }

        }
    };

    componentWillMount = () => {
        if (this.props.items && this.props.items.length) {
            let newItems = this.props.items.map(function (item, i) {
                item.key = i;
                return item;
            });

            this.setState({maxKey: newItems.length - 1});
            this.setState({items: newItems});
        }
    };

    itemRemove = (itemToRemove) => {
        let newItems = this.state.items.filter(function (item, i) {
            return !(itemToRemove.key === item.key);
        });
        this.setState({items: newItems});

    };

    itemChange = (itemToChange) => {
        let newItems = this.state.items.map(function (item, i) {
            if (item.key === itemToChange.key) {
                return itemToChange;
            }
            return item;
        });

        this.setState({items: newItems});
    };

    getItems = () => {
        return this.state.items;
    };

    componentWillReceiveProps = (nextProps) => {
        if (this.props.editMode !== nextProps.editMode) {
            let newItems = this.state.items.filter(function (item, i) {
                // remove any items that have no values
                return !(item.videoCode === '' && !item.button.active);
            });
            this.setState({items: newItems});
            //console.log(newItems);
        }
    };

    render() {

        let me = this;
        let buttons = null;

        if (this.props.editMode) {
            buttons = (
                <i onClick={this.add} className="fa fa-plus pull-right" style={{cursor: 'pointer'}}/>
            );
        }
        else {
        }

        let content = '';

        if (this.state.items.length > 0) {
            let listSize = me.state.items.length;
            let ListItems = this.state.items.map(function (item, i) {
                    return (
                        <WebinarListItem
                            key={item.key}
                            defaults={item}
                            editMode={me.props.editMode}
                            onRemove={me.itemRemove}
                            onChange={me.itemChange}
                            onPlaying={me.props.playingWebinar}
                            onAdd={me.add}
                            isLastItem={listSize - 1 === i}
                        />

                    );
                }
            );
            content = (
                <div className="user-web">
                    <ul className={this.props.editMode ? '' : 'webinar-list'}>
                        {ListItems}
                        <li ref="bottomWeb"/>
                    </ul>
                    <CustomBlock
                        name="customThree"
                        onMount={this.customBlockMount}
                        editMode={this.props.editMode}/>
                </div>
            )


        }
        else {
            content = (
                <div className="nothingToShow">
                    No Webinars Scheduled
                    {this.props.editMode ?
                        <a onClick={this.add} style={{cursor: 'pointer', display: 'block', margin: '0 auto'}}>Schedule a
                            webinar</a>
                        :
                        null
                    }
                </div>
            );
        }

        return (
            <div className="panel panel-default">
                <div className="panel-heading text-center"><h4><strong>WEBINAR SCHEDULE</strong>{buttons}</h4>

                </div>
                <div className={"panel-body "}>
                    {content}
                </div>
            </div>
        )
    };

} // WebinarList


export {WebinarList}
