import React from "react";
import LazyLoad from 'react-lazy-load';
import {Popover, OverlayTrigger} from 'react-bootstrap';

const jQuery = window.jQuery, WebFont = window.WebFont;
const UP = 38, DOWN = 40, LEFT = 37, RIGHT = 39;
// need to cache
var loadedGoogleFonts = [];
class FontSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            loading: false,
            googleFonts: [],
            fontSearch: '',
            fontFamily: ''
        };
        this.googleAPIKey = 'AIzaSyCwo3oI5ybwNKFnAL8HJQ5kaPh7r1ajiVk';

        this.standardFonts = [
            'Arial, sans-serif',
            'Helvetica, sans-serif',
            'Verdana, sans-serif',
            'Palatino',
            'Garamond',
            'Verdana',
            'Georgia',
            'Comic Sans MS',
            'Trebuchet MS',
            'Impact',
            'Comic Sans',
            'Times New Roman, Serif',
            'Arial Black',
        ];

        this.loadedFonts = [];
        this.fontsToLoad = [];
    } // constructor()

    loadList = () => {
        let googleFonts = [];
        this.setState({loading: true});

        // google fonts
        jQuery.ajax({
            dataType: "json",
            url: 'https://www.googleapis.com/webfonts/v1/webfonts?key=' + this.googleAPIKey,
            type: 'GET',
            success: (data) => {

                this.setState({loading: false});

                data.items.forEach(function (item) {
                    if (jQuery.inArray('regular', item.variants) === -1) {
                        // only show regular variants
                        return;
                    }

                    if (jQuery.inArray(item.family, googleFonts) === -1) {
                        //fontFamilies.push(item.family);
                        googleFonts.push(item.family);
                    }
                });

                this.setState({googleFonts: googleFonts});
            }
        });
    };

    loadGoogleFontFiles = () => {
        if (this.fontsToLoad.length === 0) {
            return;
        }
        // only request 100 at a time from google
        let fontsToLoad = this.fontsToLoad.splice(0, 100);
        this.fontsToLoad.slice(0, 99);
        this.loadedFonts = this.loadedFonts.concat(fontsToLoad);
        WebFont.load({
            google: {
                families: fontsToLoad
            },
            classes: false,
            events: false
        });
        //console.log(fontsToLoad, 'end');
    };

    makeFontVisible = (fontFamily) => {
        // for use with lazyload
        if (jQuery.inArray(fontFamily, loadedGoogleFonts) !== -1) {
            return;
        }

        this.fontsToLoad.push(fontFamily);
        //console.log(fontFamily);
        //console.log(this.fontsToLoad);

        // throttle
        clearTimeout(this.throttleHandle);
        this.throttleHandle = setTimeout(() => {
            // clone array first
            this.loadGoogleFontFiles();
        }, 200);
    };

    handleSimpleChange = (event, callback) => {
        let fieldDetails = {};
        fieldDetails[event.target.name] = event.target.value;
        //console.log(fieldDetails);
        this.setState(fieldDetails, () => {
            if (typeof callback === 'function') {
                callback();
            }
        });
    };

    handleSearchChange = (event, callback) => {
        console.log(event.target.value);
        this.setState({fontSearch: event.target.value}, () => {
            if (typeof callback === 'function') {
                callback();
            }
        });
    };

    componentWillMount = () => {
        this.loadList();
    };

    onKeyDown = (event) => {
        return;

        let newSelected = 0;
        console.log(event.type);
        console.log(event.keyCode);
        if (event.keyCode === UP || event.keyCode === LEFT) {
            newSelected = this.state.selected - 1;
            if (newSelected < 0) {
                newSelected = this.state.googleFonts.length - newSelected;
            }
        }
        if (event.keyCode === DOWN || event.keyCode === RIGHT) {
            newSelected = this.state.selected + 1;
        }
        this.setState({selected: newSelected});
    };

    onClick = (event, font) => {
        this.setState({fontFamily: font.fontFamily});
        if (typeof this.props.onSelect == 'function') {
            this.props.onSelect(font);
        }
    };

    generateGoogleList = () => {
        let search = new RegExp(this.state.fontSearch.replace(/[^a-zA-Z 0-9]+/g, ''), 'i');
        let list = this.state.googleFonts.map((fontFamily, i) => {
            let style = {};
            if (this.state.selected === i) {
                style = {backgroundColor: '#000099', color: '#FFFFFF'};
            }

            if (fontFamily.length && fontFamily.search(search) === -1) {
                return null;
            }

            return (
                <li key={i} style={{listStyleType: 'none', padding: 0, margin: 0, ...style}} onClick={(event) => {
                    this.onClick(event, {fontFamily: fontFamily, source: 'GoogleFonts'})
                }}>
                    <LazyLoad
                        height={'2em'}
                        offsetVertical={1000}
                        onContentVisible={() => this.makeFontVisible(fontFamily)}
                        debounce={false}
                    >
						<span style={{
                            fontFamily: fontFamily,
                            fontSize: '1em',
                            lineHeight: '2em',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                        }}>
							<strong>{fontFamily}</strong> - The quick brown fox jumped over the zamboni.
						</span>
                    </LazyLoad>
                </li>
            );
        });

        return (
            <div>
                <h3>Google Fonts</h3>
                <ul style={{padding: 0, margin: 0, cursor: 'pointer'}}>
                    {list}
                </ul>
            </div>
        );
    };

    generateStandardList = () => {
        let search = new RegExp(this.state.fontSearch.replace(/[^a-zA-Z 0-9]+/g, ''), 'i');
        console.log(search);
        let list = this.standardFonts.map((fontFamily, i) => {
            let style = {};

            return (
                <li key={i} style={{listStyleType: 'none', padding: 0, margin: 0, ...style}} onClick={(event) => {
                    this.onClick(event, {fontFamily: fontFamily, source: 'StandardFonts'})
                }}>

					<span style={{
                        fontFamily: fontFamily,
                        fontSize: '1em',
                        lineHeight: '2em',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                    }}>
						<strong>{fontFamily}</strong> - The quick brown fox jumped over the zamboni.
					</span>
                </li>
            );
        });

        return (
            <div>
                <h3>Standard Fonts</h3>
                <ul style={{padding: 0, margin: 0, cursor: 'pointer'}}>
                    {list}
                </ul>
            </div>
        );
    };

    render() {

        let popoverStyle = {};
        if (this.input) {
            popoverStyle = {width: jQuery(this.input).innerWidth()};
        }

        let popover = (
            <Popover id="font-selector-popover" className="font-selector-popover"
                     style={{
                         background: '#FFFFFF',
                         border: 'none',
                         boxShadow: 'none',
                         WebkitBoxShadow: 'none',
                         overflow: 'auto',
                         width: '100%',
                         maxWidth: 600,
                         height: 300,
                         ...popoverStyle
                     }}>
                <input
                    placeholder="Font Search"
                    name="fontSearch"
                    type="text"
                    className="form-control"
                    onChange={this.handleSearchChange}
                    onClick={() => {
                        console.log('onClick')
                    }}
                    onFocus={() => {
                        console.log('onFocus')
                    }}
                    onBlur={() => {
                        console.log('onBlur')
                    }}
                    value={this.state.fontSearch}

                />
                {this.generateStandardList()}
                {this.generateGoogleList()}
            </Popover>
        );

        let googleFont = (
            <OverlayTrigger ref="trigger" trigger={['click']} placement={'bottom'} overlay={popover} rootClose>
                <input ref={ref => {
                    this.input = ref
                }} type="text" className="form-control" value={this.props.fontFamily} onChange={console.log('AAA')}/>
            </OverlayTrigger>
        );

        return googleFont;
    }
} // FontSelector

export {FontSelector};