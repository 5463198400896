import React from "react";
import {ChromePicker} from "react-color";

const jQuery = window.jQuery, bootbox = window.bootbox, WebFont = window.WebFont, moment = window.moment;

class Styler extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            background: jQuery(jQuery('#main')).css('background-color')
        };
        this.handleChangeComplete = this.handleChangeComplete.bind(this);
        this.handleUndo = this.handleUndo.bind(this);
    } // constructor()

    handleChangeComplete(color) {
        jQuery(jQuery('#main')).css('background-color', 'rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')');
        let background = 'rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')';
        this.setState({background: background});
        this.props.onChange(this.state.background);
    }

    handleUndo() {
        this.setState({background: this.backgroundOrig});
        jQuery(jQuery('#main')).css('background-color', this.backgroundOrig);
    }

    componentWillMount() {
        this.backgroundOrig = jQuery(jQuery('#main')).css('background-color');
    }

    render() {
        return (
            <div className="" style={{textAlign: 'center'}}>
                <ChromePicker
                    color={this.state.background}
                    onChangeComplete={this.handleChangeComplete}
                    style={{boxShadow: 'none'}}
                />
                <div className="btn-group" role="group">
                    <button className="btn btn-default btn-sm" onClick={this.handleUndo}><i className="fa fa-undo"
                                                                                            style={{
                                                                                                cursor: 'pointer',
                                                                                                color: '#000'
                                                                                            }}/> Undo
                    </button>
                    <button className="btn btn-default btn-sm" onClick={this.props.onClose}><i className="fa fa-remove"
                                                                                               style={{
                                                                                                   cursor: 'pointer',
                                                                                                   color: '#000'
                                                                                               }}/> Close
                    </button>
                </div>
            </div>
        );
    }
} // Styler

export {Styler}