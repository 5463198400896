import React from "react";
import BasicOverlay from './BasicOverlay.jsx';
import tf from './shared/tf.jsx';

const $ = window.$, jQuery = window.jQuery, bootbox = window.bootbox;

class ContactUsRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            nameInclude: false,
            emailInclude: false,
            phoneInclude: false,
            commentInclude: false,

            name: '',
            email: '',
            phone: '',
            comment: ''

        };
    } // constructor()

    handleNameChange = (event) => {
        this.setState({
            name: event.target.value
        })
    };
    handleEmailChange = (event) => {
        this.setState({
            email: event.target.value
        })
    };
    handlePhoneChange = (event) => {
        this.setState({
            phone: event.target.value
        })
    };
    handleCommentChange = (event) => {
        this.setState({
            comment: event.target.value
        })
    };

    open = () => {
        this.setState({showModal: true});
    }; // open()

    close = () => {
        this.setState({showModal: false});
    }; // open()

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        })
    };

    submit = () => {
        $('#name').removeClass('has-error');
        $('#email').removeClass('has-error');
        $('#phone').removeClass('has-error');
        $('#comment').removeClass('has-error');
        let params = {};
        params.name = this.state.name;
        params.email = this.state.email;
        params.phone = this.state.phone;
        params.comment = this.state.comment;
        if (this.props.webinarId === 0) {
            this.close();
            bootbox.alert("Please save the webinar first!");
        }
        else {
            let hasError = false;
            if (this.state.nameInclude && params.name.trim() === "") {
                hasError = true;
                $('#name').addClass('has-error');
            }
            if (this.state.emailInclude && params.email.trim() === "") {
                hasError = true;
                $('#email').addClass('has-error');
            }
            if (this.state.phoneInclude && params.phone.trim() === "") {
                hasError = true;
                $('#phone').addClass('has-error');
            }
            if (this.state.commentInclude && params.comment.trim() === "") {
                hasError = true;
                $('#comment').addClass('has-error');
            }
            if (hasError) {

            } else {
                params.webinarId = this.props.webinarId;
                jQuery.ajax({
                    dataType: "json",
                    async: false,
                    url: '/w/rpc/',
                    type: "POST",
                    contentType: 'application/json',
                    xhrFields: {
                        withCredentials: true
                    },
                    data: JSON.stringify({method: 'submit', params: params}),
                    success: (data) => {
                        if (typeof data.result !== 'undefined') {
                            if (data.result) {
                                this.setState({
                                    name: '',
                                    email: '',
                                    phone: '',
                                    comment: ''
                                });
                                this.close();
                            }
                            else {
                                tf.rpcError({error: 'Unable to save.'});
                            }
                        }
                        else if (data.error) {
                            tf.rpcError(data);
                        }
                        else {
                            // unknown
                        }
                    }

                });
            }
        }

    };

    render() {
        let button, show;
        button = (
            <button className="btn btn-default" onClick={() => this.open()}>
                Contact Us
            </button>
        );
        if (this.props.editMode) {
            show = (<form className="form"><BasicOverlay showModal={this.state.showModal} onToggle={this.onToggle}>

                <h3>Check the information you need</h3>
                <div className="form-group">
                    <div className="checkbox">
                        <label><input type="checkbox" name="nameInclude" checked={this.state.nameInclude}
                                      onChange={this.handleChange}/> Name</label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="checkbox">
                        <label><input type="checkbox" name="emailInclude" checked={this.state.emailInclude}
                                      onChange={this.handleChange}/> Email</label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="checkbox">
                        <label><input type="checkbox" name="phoneInclude" defaultChecked={this.state.phoneInclude}
                                      onChange={this.handleChange}/> Phone</label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="checkbox">
                        <label><input type="checkbox" name="commentInclude" defaultChecked={this.state.commentInclude}
                                      onChange={this.handleChange}/> Comment</label>
                    </div>
                </div>
                <button type="button" className="btn btn-default btn-sm" onClick={this.close}>Close</button>
            </BasicOverlay></form>);
        }
        else {
            let name, email, phone, comment = null;
            if (!this.state.nameInclude && !this.state.emailInclude && !this.state.phoneInclude && !this.state.commentInclude) {
                button = null;
            }
            else {
                if (this.state.nameInclude) {
                    name = (<div id="name" className="form-group">
                        <label className="col-sm-2 control-label">Name: </label>
                        <div className="col-sm-10" style={{marginBottom: 10}}>
                            <input type="text" className="form-control" placeholder="First name Last name"
                                   value={this.state.name}
                                   onChange={this.handleNameChange}/>
                        </div>
                    </div>);
                }
                if (this.state.emailInclude) {
                    email = (<div id="email" className="form-group" style={{marginBottom: 10}}><label
                        className="col-sm-2 control-label">E-mail: </label>
                        <div className="col-sm-10" style={{marginBottom: 10}}><input type="email"
                                                                                     className="form-control"
                                                                                     placeholder="example@example.com"
                                                                                     value={this.state.email}
                                                                                     onChange={this.handleEmailChange}/>
                        </div>
                    </div>);
                }
                if (this.state.phoneInclude) {
                    phone = (<div id="phone" className="form-group" style={{marginBottom: 10}}><label
                        className="col-sm-2 control-label">
                        Phone: </label>
                        <div className="col-sm-10" style={{marginBottom: 10}}><input type="tel" className="form-control"
                                                                                     placeholder="+1(613)-555-5555"
                                                                                     value={this.state.phone}
                                                                                     onChange={this.handlePhoneChange}/>
                        </div>
                    </div>);
                }
                if (this.state.commentInclude) {
                    comment = (<div id="comment" className="form-group" style={{marginBottom: 10}}>
                        <label className="col-sm-2 control-label">Comment: </label>
                        <div className="col-sm-10" style={{marginBottom: 10}}>
						<textarea placeholder="Please enter your comment" className="form-control"
                                  value={this.state.comment}
                                  onChange={this.handleCommentChange}>{this.state.comment}</textarea></div>
                    </div>);
                }
                show = (<BasicOverlay showModal={this.state.showModal} onToggle={this.onToggle}>
                    <form className="form">
                        <h3>Information</h3>
                        {name}
                        {email}
                        {phone}
                        {comment}
                    </form>
                    <div className="text-center">
                        <button type="button" className="btn btn-primary" onClick={this.submit}>Submit</button>
                    </div>
                </BasicOverlay>);
            }
        }
        return (
            <div>
                {button}
                {show}
            </div>
        );

    }

}// ContactUsRequest

export {ContactUsRequest}