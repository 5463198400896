import React from "react";

class TestimonialListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            editMode: false,
            testimonialText: '',
            testimonialAuthor: '',
            key: 0,
            id: 0
        }
    }

    handleTestimonialChange = (e) => {
        this.setState({
            testimonialText: e.target.value
        }, () => {
            this.props.onChange(this.state);
        });
    };

    handleTestimonialAuthorChange = (e) => {
        this.setState({
            testimonialAuthor: e.target.value
        }, () => {
            this.props.onChange(this.state);
        });
    };

    componentWillMount = () => {
        if (this.props.defaults) {
            this.setState(this.props.defaults);
        }
    };

    toggleModes = () => {
        this.setState({
            editMode: !this.state.editMode,
        });
    };

    render() {
        let content = null;
        let addButton = null;
        if (this.props.isLastItem) {
            addButton = (
                <button onClick={() => {
                    this.props.onAdd()
                }} className="btn btn-primary"><i className="fa fa-plus"/> Add
                </button>
            );
        }
        if (this.props.editMode) {
            content = (<div className="well well-sm">
                <div className="">
                    <div className="form-group">
                        <label className="control-label">Testimonial Text </label>
                        <textarea placeholder="Please enter your testimonial" className="form-control"
                                  value={this.state.testimonialText}
                                  onChange={this.handleTestimonialChange}>{this.state.testimonialText}</textarea>
                    </div>

                    <div className="form-group">
                        <label className="control-label">Testimonial Author </label>
                        <input placeholder="Please enter author name" type="text" className="form-control"
                               value={this.state.testimonialAuthor} onChange={this.handleTestimonialAuthorChange}/>
                    </div>
                    <div className="clearfix"/>
                </div>

                <div className="form-group">
                    <button onClick={() => {
                        this.props.onRemove(this.state)
                    }} className="btn btn-warning" style={{marginRight: 10}}><i className="fa fa-remove"/> Remove
                    </button>
                    {addButton}
                </div>
                <div className="clearfix"/>
            </div>);
        }
        else {

            content = (<div className="text-left" style={{display: this.props.isActive ? 'block' : 'none'}}>
                <q><i>{this.state.testimonialText}</i></q>
                <br/>
                <br/>
                <i> — {this.state.testimonialAuthor}</i>
            </div>);
        }

        return (
            <li style={{padding: '5px'}}>
                {content}
            </li>
        )
    }

} // TestimonialListItem

export {TestimonialListItem}