import React from "react";
import ReactDOM from "react-dom";
import {SocialListItem} from "./SocialListItem";

const jQuery = window.jQuery, bootbox = window.bootbox;
class SocialList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            maxKey: -1
        }
    }

    add = () => {
        let newItems = this.state.items.slice();
        let count = 0;
        for (let item of newItems) {
            if (item.url === 'https://' || item.name === '') {
                count++;
            }
        }
        if (count > 0) {
            bootbox.alert('Please complete the social link form then add new');
        }
        else {
            newItems.push({
                url: 'https://',
                name: '',
                key: this.state.maxKey + 1
            });
            this.setState({maxKey: this.state.maxKey + 1});
            this.setState({items: newItems});
            let node = ReactDOM.findDOMNode(this.refs.bottomSoc);
            if (node != null) {
                let heightSoc = window.pageYOffset + node.getBoundingClientRect().top;
                jQuery("html, body").animate({scrollTop: heightSoc}, 600);
            }

        }
    };

    componentWillMount = () => {
        if (this.props.items && this.props.items.length) {
            let newItems = this.props.items.map(function (item, i) {
                item.key = i;
                return item;
            });

            this.setState({maxKey: newItems.length - 1});
            this.setState({items: newItems});

        }
    };

    itemRemove = (itemToRemove) => {
        let newItems = this.state.items.filter(function (item, i) {
            return !(itemToRemove.key === item.key);
        });

        this.setState({items: newItems});
    };

    itemChange = (itemToChange) => {
        let newItems = this.state.items.map(function (item, i) {
            if (item.key === itemToChange.key) {
                return itemToChange;
            }
            return item;
        });

        this.setState({items: newItems});
    };

    getItems = () => {
        return this.state.items;
    };

    componentWillReceiveProps = (nextProps) => {
        if (this.props.editMode !== nextProps.editMode) {
            let newItems = this.state.items.filter(function (item, i) {
                // remove any items that have no values
                return !(item.name === '' || (item.url === 'https://' || item.url === ''));
            });

            this.setState({items: newItems});
        }
    };

    render() {

        let me = this;
        let buttons = null;

        if (this.props.editMode) {
            buttons = (
                <i onClick={this.add} className="fa fa-plus pull-right" style={{cursor: 'pointer'}}/>
            );
        }
        else {
            if (this.state.items.length === 0) {
                // if not in edit mode and there is nothing to show don't show entire section
                return null;
            }
        }

        let content = '';

        if (this.state.items.length > 0) {
            let listSize = me.state.items.length;

            let ListItems = this.state.items.map(function (item, i) {
                return (

                    <SocialListItem
                        key={item.key}
                        defaults={item}
                        editMode={me.props.editMode}
                        onRemove={me.itemRemove}
                        onChange={me.itemChange}
                        onAdd={me.add}
                        isLastItem={listSize - 1 === i}
                    />

                );
            });

            content = (
                <ul className={this.props.editMode ? '' : 'social-list'}>
                    {ListItems}
                    <li ref="bottomSoc"/>
                </ul>
            );
        }
        else {
            content = (
                <div className="nothingToShow">
                    No Social Links Configured
                    {this.props.editMode ?
                        <a onClick={this.add} style={{cursor: 'pointer', display: 'block', margin: '0 auto'}}>Add Social
                            Link</a>
                        :
                        null
                    }
                </div>
            );
        }

        return (
            <div className="panel panel-default">
                <div className="panel-heading text-center"><h4><b>Follow Us</b>{buttons}</h4>
                </div>
                <div className="panel-body">
                    {content}
                </div>
            </div>
        )
    }
} // SocialList

export {SocialList}