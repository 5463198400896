import React from "react";
import {FormattedMessage, IntlProvider} from "react-intl";

const jQuery = window.jQuery, bootbox = window.bootbox, moment = window.moment;

class Countdown extends React.Component {

    componentWillUnmount = () => {
        clearInterval(this.timmerHandle);
    };

    componentDidMount = () => {
        this.timmerHandle = window.setInterval(function () {
            this.forceUpdate()
        }.bind(this), 1000);
    };

    render() {
        let countdowntext;
        if (this.props.activeVideo) {
            countdowntext = (<span>Playing Now</span>);
        }
        else if (this.props.nextVideo) {
            let now = moment().unix();
            let hour = this.props.nextVideo.hour * 1, minute, second;
            if (this.props.nextVideo.meridiem === 'pm') {
                if (hour !== 12) {
                    hour += 12;
                }
            }
            else {
                if (hour === 12) {
                    hour = 0;
                }
            }

            let videoStart = moment().set({hour: hour, minute: this.props.nextVideo.minute, second: 0}).unix();
            let when;
            if (videoStart < now) {
                when = moment().add(1, 'days').set({hour: hour, minute: this.props.nextVideo.minute, second: 0});
            }
            else {
                when = moment().set({hour: hour, minute: this.props.nextVideo.minute, second: 0});
            }


            let secondsUntil = when.unix() - now;

            hour = Math.floor(secondsUntil / 3600);
            secondsUntil = secondsUntil % 3600;
            minute = Math.floor(secondsUntil / 60);
            secondsUntil = secondsUntil % 60;
            second = secondsUntil;

            countdowntext = (
                <IntlProvider locale="en">
                    <FormattedMessage
                        id="webinar-countdown"
                        defaultMessage={`{hour, number} {hour, plural, one {hour} other {hours}} {minute, number} {minute, plural, one {minute} other {minutes}} {second, number} {second, plural, one {second} other {seconds}}`}
                        values={{hour, minute, second}}
                    />
                </IntlProvider>
            );

        }
        else {
            countdowntext = (<span>There is no video!</span>);
        }

        return (<span>{countdowntext}</span>)
    }

} // Countdown

export {Countdown}