import React from "react";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {Styler} from "./Styler";

const styles = {
    editIcon: {
        fontSize: '15px'
    },
};


class BackgroundButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            position: 'top',
            backgroundColor: '',
        };
        this.toggle = this.toggle.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.handleOverlayExit = this.handleOverlayExit.bind(this);
        this.handleBackgroundColor = this.handleBackgroundColor.bind(this);
    } // constructor()


    handleOverlayExit() {
        if (!this.state.visible) {
            this.forceUpdate();
        }
    }

    handleBackgroundColor = (event) => {
        this.setState({
            backgroundColor: event
        });
        this.props.onChange(this.state.backgroundColor);
    };

    render() {

        let button = null;
        if (this.props.editMode) {
            const popover = (
                <Popover id="bg-editable-popover" className="bg-editable-popover" style={{
                    background: 'transparent',
                    border: 'none',
                    boxShadow: 'none',
                    WebkitBoxShadow: 'none'
                }}>
                    <Styler onClose={this.close} onChange={this.handleBackgroundColor}/>
                </Popover>
            );
            button = (
                <OverlayTrigger ref="trigger" trigger="click" placement={this.state.position} overlay={popover}
                                onExited={this.handleOverlayExit}>
                    <button type="button" ref="button" className="btn btn-default btn-sm" style={styles.editButton}>
                        <i className="fa fa-pencil" style={styles.editIcon}/>
                    </button>
                </OverlayTrigger>
            );
        }

        return (
            <div className="bg-editable-button" style={{position: 'relative'}}>
                {button}
            </div>
        );
    } // render()

    toggle() {
        this.setState({showModal: !this.state.showModal});
    }

    close() {
        this.setState({showModal: false});
        this.refs.trigger.handleHide();
    }

    open() {
        this.setState({showModal: true});
    }

} // BackgroundButton

export {BackgroundButton}