import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-overlays';

const styles = {
    modal: {
        position: 'fixed',
        zIndex: 1040,
        top: 0, bottom: 0, left: 0, right: 0
    },
    backdrop: {
        position: 'fixed',
        top: 0, bottom: 0, left: 0, right: 0,
        zIndex: 'auto',
        backgroundColor: '#000',
        opacity: 0.5
    },
    dialog: {
        position: 'absolute',
        top: 0, bottom: 0, left: 0, right: 0,
        border: '1px solid #e5e5e5',
        backgroundColor: 'white',
        boxShadow: '0 5px 15px rgba(0,0,0,.5)',
        padding: 10,
        overflowY: 'auto',
    },
};

class BasicOverlay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: !!this.props.showModal
        };
        this.toggle = this.toggle.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    } // constructor()

    handleChange = (e) => {
        if (this.props.onSelect) {
            this.props.onSelect(e);
        }
        this.close();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.showModal !== this.props.showModal) {
            if (nextProps.showModal) {
                this.open();
            }
            else {
                this.close()
            }
        }
    }

    render() {
        return (
            <div>
                <Modal
                    aria-labelledby='modal-label'
                    style={Object.assign({}, styles.modal, this.props.modalStyles)}
                    backdropStyle={Object.assign({}, styles.backdrop, this.props.backdropStyles)}
                    show={this.state.showModal}
                    onHide={this.close}
                >
                    <div style={Object.assign({}, styles.dialog, this.props.dialogStyles)}>
                        {this.props.showClose ?
                            <a onClick={this.close} className="pull-right" style={{cursor: 'pointer'}}>
                                <i className="fa fa-times fa-2x" aria-hidden="true" title="Close"/></a> : ''}
                        <div className="clearfix"/>
                        {this.props.children}
                    </div>
                </Modal>
            </div>
        );
    } // render()

    toggle(e) {
        if (e) e.preventDefault();
        if (this.showModal) {
            this.close();
        }
        else {
            this.open();
        }
    }

    close() {
        console.log(this.state.showModal);
        if (this.state.showModal === false) {
            // already set
            return;
        }
        this.setState({showModal: false}, () => {
            if (this.props.onClose) {
                this.props.onClose();
            }
            if (this.props.onToggle) {
                this.props.onToggle(this.state.showModal);
            }
        });
    }

    open() {
        if (this.state.showModal === true) {
            // already set
            return;
        }
        this.setState({showModal: true}, () => {
            if (this.props.onOpen) {
                this.props.onOpen();
            }
            if (this.props.onToggle) {
                this.props.onToggle(this.state.showModal);
            }
        });
    }
} // BasicOverlay


BasicOverlay.propTypes = {
    className: PropTypes.string,
    showModal: PropTypes.bool,
    showClose: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onToggle: PropTypes.func,
    modalStyles: PropTypes.object,
    backdropStyles: PropTypes.object,
    dialogStyles: PropTypes.object,
};

BasicOverlay.defaultProps = {
    className: '',
    showModal: false,
    showClose: true,
    modalStyles: {},
    backdropStyles: {},
    dialogStyles: {},
};


export default BasicOverlay;